import React from "react"
import { Typography, Box } from "@mui/material";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

function DeliveryBlock() {

    return(
        <>
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'left',
                    alignItems: 'center',
                    width: '95%',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
                >

                <LocalShippingOutlinedIcon sx={{mr: '2%'}}/>

                <Typography sx={{
                    fontSize: {xs: 16, md: 20}, 
                    fontFamily: 'Neuton', 
                    fontWeight: 600}}>
                    Delivery:
                </Typography>

            </Box>

            <Box sx={{
                width: '95%', 
                display: 'flex', 
                justifyContent: 'left', 
                alignItems: 'center',}}>
                <Typography 
                sx={{
                    marginBottom: '16px',
                    fontFamily: 'Neuton',
                    fontWeight: 300, 
                    lineHeight: 1.5, 
                    fontSize: {xs: 16, md: 18}}}>
                    This product can be shipped to 48 continental States.
                </Typography>
            </Box>

            <Box sx={{
                width: '95%', 
                display: 'flex', 
                justifyContent: 'left', 
                alignItems: 'center',}}>
                <Typography 
                sx={{
                    marginBottom: '16px',
                    fontFamily: 'Neuton', 
                    fontWeight: 300, 
                    lineHeight: 1.5, 
                    fontSize: {xs: 16, md: 18}}}>
                View Delivery Information and Charges 
                </Typography>
            </Box>
        </>
    )
}

export default DeliveryBlock