import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProductContextProvider from './context/products-context';
import CartContextProvider from './context/cart-context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UserContextProvider from './context/user-context';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Scroller from './components/scroller';

const root = ReactDOM.createRoot(document.getElementById('root'));

const stripePromise = loadStripe(
    process.env.REACT_APP_PUBLISHABLE_KEY
    )

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system', 
            'BlinkMacSystemFont',
            'Source Serif 4, serif'
        ].join(','),
    }
})

root.render(

    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Scroller />
                <ProductContextProvider>
                    <CartContextProvider>
                        <Elements stripe={stripePromise}>
                            <UserContextProvider>
                                <App />
                            </UserContextProvider>
                        </Elements>
                    </CartContextProvider>
                </ProductContextProvider>
        </BrowserRouter>
    </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
