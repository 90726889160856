import React from "react"
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";


function TitleBlock({Category, Name, Size}) {

    return(
        <>
            <Typography 
                textAlign={'left'} 
                gutterBottom
                sx={{
                    fontSize: {xs: 20, md: 24}, 
                    lineHeight: 1.5,
                    fontFamily: 'Neuton', 
                    fontWeight: 200,
                    }}>
                {Category}
            </Typography>

            <Typography 
                textAlign={'left'} 
                gutterBottom
                sx={{
                    fontSize: {xs: 28, md: 32}, 
                    lineHeight: 1.5,
                    fontFamily: 'Neuton', 
                    fontWeight: 300,
                    color: grey[800],
                    // color: 'primary.dark',
                    }}>
                {Name}
            </Typography>

            <Typography 
                textAlign={'left'} 
                gutterBottom
                sx={{
                    fontSize: {xs: 20, md: 24}, 
                    lineHeight: 1.5,
                    fontFamily: 'Neuton', 
                    fontWeight: 200,
                    }}>
                {Size}
            </Typography>
        </>
    )
}

export default TitleBlock