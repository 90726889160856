import React , { useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { CartContext } from "../context/cart-context";
import Layout from './layout';
import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
import CartItem from "./cart-item";
  
// function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//         let location = useLocation();
//         let navigate = useNavigate();
//         let params = useParams();
//         return (
//             <Component
//             {...props}
//             router={{ location, navigate, params }}
//             />
//         );
//     }
  
//     return ComponentWithRouterProp;
// }

const Success = () => {
    const { clearCart, cartItems } = useContext(CartContext)
    useEffect(() => {
        if (cartItems.length !== 0) {clearCart()}
    }, [clearCart, cartItems])

    return(
        <Layout>
            <p1>Thank you for your order.</p1>
        </Layout>
    )
}

// export default withRouter(Success)
export default Success