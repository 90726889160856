import React from "react";
import Layout from "./layout";
import { Box, Button, Typography } from "@mui/material";

const NotFound = () => {
    return(
        <Layout>
            <Box alignItems={'center'} justifyContent={'space-evenly'} sx={{height: '50vh'}}>
                <Typography variant="h5" textAlign={'center'} sx={{mt: '2%', mb: '2%'}}>
                    Unfortunately, we cannot find that page!
                </Typography>
                {/* <Button>
                    Home
                </Button> */}
            </Box>
        </Layout>
    )
}

export default NotFound