import React from "react";
import Layout from "../components/layout";
import { Box, 
    // Button, 
    // Card, 
    // CardMedia, 
    Container, 
    Stack, 
    Grid,
    // Divider, 
    Typography 
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Helmet } from 'react-helmet';
import TG from '../assets/tg3.JPG';
// import Biz from '../assets/corp_sm.jpg';
import Biz from '../assets/biz2.jpeg';
import Biz2 from '../assets/corp_ml.jpg';
import Pers from '../assets/personal_sm.jpg';
import Pers2 from '../assets/personal_ml.jpg';

const Homepage = () => {

    return(

        <div>
            <Helmet>
                <title>Welcome to The Pantry Bakery & Catering</title>
                <meta name="description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
                <meta name="keywords" content="pantry, pantry bakery, pantry catering, event catering, plant-based catering, organic catering, authentic korean food catering, low fat lunch, low sodium lunch box, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch catering, executive breakfast, corporate event catering, fresh, special event lunch box" />

                <meta property="og:title" content={'Welcome to The Pantry Bakery and Catering'} />
                <meta property="og:description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Welcome to The Pantry Bakery and Catering'}  />
                <meta name="twitter:description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg'>

                    <Stack 
                        useFlexGap 
                        // rowGap={2} 
                        justifyContent="center" 
                        alignItems="stretch" 
                        sx={{
                            width: '100%',
                            pt: '4rem',
                            }}>

                        {/* OPENNING PICTURE */}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // height: '30%',
                            // background:'blue',
                            mt: 2
                            }}>
                           <img 
                                src={TG}
                                // src={'https://le-marke.co/cdn/shop/files/LM_Holidays_2023-60240006_1400x.jpg?v=1700856282'} 
                                alt="The pantry logo on a shadowed background" 
                                style={{
                                    // maxHeight: '5%', 
                                    maxWidth: '95%', 
                                    borderRadius: '0.5rem'
                                    }} />
                        </Box>

                        {/* DESCRIPTION */}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            mt: 1
                            }}>

                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    width: '95%',
                                    fontSize: {xs: 18, md: 20},
                                    lineHeight: {xs: 1.5, md: 2},
                                    fontFamily: 'Neuton',
                                    fontWeight: 300, 
                                    color: grey[900]}}>

                                Located in the heart of Southern California, our catering company takes pride in offering 
                                a unique culinary experience centered around locally sourced, fresh, and mostly organic 
                                ingredients. Our commitment to quality is unwavering, as we prepare each dish fresh and 
                                immediately after receiving the order. This dedication ensures that our customers receive 
                                the best quality products, with flavors and nutrients at their peak. Our services are 
                                designed to cater to both corporate events and special occasions, providing a delightful 
                                and memorable gastronomic experience.

                            </Typography>

                        </Box>

                        {/* PHILOSOPHY HEADER */}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'orange',
                            mt: 1,
                            }}>
                            <Typography 
                                textAlign={'left'}
                                gutterBottom
                                sx={{
                                    lineHeight: 2,
                                    width: '95%',
                                    fontSize: {xs: 24, md: 28},
                                    // fontFamily: 'Blaster',
                                    fontFamily: 'Neuton',
                                    fontWeight: 400, 
                                    color: grey[900]}}>
                                Our Philosophy
                            </Typography>
                        </Box>

                        {/* PHILOSOPHY ITEMS */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            mt: 1
                            }}>

                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    width: '95%',
                                    mb: '1rem',
                                    fontSize: {xs: 18, md: 20},
                                    lineHeight: {xs: 1.5, md: 2},
                                    fontFamily: 'Neuton',
                                    fontWeight: 300, 
                                    color: grey[900]}}>
                                    At The Pantry Bakery & Catering, we believe that every moment is an opportunity to savor, connect, 
                                    and create meaning. As a modern market and grocer, our carefully curated offerings are designed to 
                                    elevate the present—whether it's through the taste of artisanal baked goods, the discovery of 
                                    thoughtfully sourced ingredients, or the shared joy of a meal.
                            </Typography>

                            <Typography 
                                textAlign={'justify'}
                                sx={{
                                    width: '95%',
                                    fontSize: {xs: 18, md: 20},
                                    lineHeight: {xs: 1.5, md: 2},
                                    fontFamily: 'Neuton',
                                    fontWeight: 300, 
                                    color: grey[900]}}>
                                    We are equally committed to a sustainable tomorrow. By prioritizing eco-friendly practices, local 
                                    partnerships, and responsibly crafted products, we honor our responsibility to the planet while 
                                    supporting a vibrant, conscious community. The Pantry isn't just about food; it's about fostering a 
                                    way of life that balances indulgence with integrity, and passion with purpose.
                            </Typography>
                        </Box>

                        {/* CORPORATE HEADER */}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'red',
                            mt: 1,
                            }}>
                            <Typography 
                                textAlign={'justify'}
                                gutterBottom
                                sx={{
                                    lineHeight: 2,
                                    width: '95%',
                                    fontSize: {xs: 24, md: 28},
                                    fontFamily: 'Neuton',
                                    fontWeight: 400, 
                                    color: grey[900]}}>
                                    Event Catering & Corporate Lunch
                            </Typography>
                        </Box>

                        {/* CORPORATE ITEMS - XS to MD*/}
                        <Box sx={{
                            display: {xs: 'flex', md: 'none'},
                            // display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'yellow',
                            mt: 1
                            }}>

                            <Grid container>

                                <Grid item xs={12} md={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        width: '100%',
                                        // background: 'yellow',
                                        mt: 1
                                        }}>

                                        <Typography 
                                            paragraph
                                            textAlign={'justify'}
                                            sx={{
                                                width: '95%',
                                                fontSize: {xs: 18, md: 20},
                                                lineHeight: {xs: 1.5, md: 2},
                                                fontFamily: 'Neuton',
                                                fontWeight: 300, 
                                                color: grey[900]}}>
                                                For our corporate clients, we specialize in creating tailored catering solutions for breakfasts, 
                                                lunches, team-building activities, and executive brunches. We understand the importance of these 
                                                events in fostering team spirit and productivity, and our gourmet offerings are crafted to impress 
                                                and energize.
                                        </Typography>

                                        <Typography 
                                            paragraph
                                            textAlign={'justify'}
                                            sx={{
                                                width: '95%',
                                                fontSize: {xs: 18, md: 20},
                                                lineHeight: {xs: 1.5, md: 2},
                                                fontFamily: 'Neuton',
                                                fontWeight: 300, 
                                                color: grey[900]}}>
                                                From nutritious and delicious breakfast options to sophisticated brunch 
                                                spreads and hearty lunches, our menus are designed to meet the diverse needs of modern corporate 
                                                settings. We aim to elevate every corporate gathering with our fresh, flavorful, and beautifully 
                                                presented dishes.
                                        </Typography>

                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={5} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center', 
                                            justifyContent: 'center',
                                            width: '95%',
                                            // height: '50%',
                                            // background: 'cyan',
                                            mt: 1
                                            }}>
                                        <img 
                                            src={Biz} 
                                            alt="Series of gift boxes" 
                                            style={{
                                                maxHeight: '100%', 
                                                maxWidth: '100%', 
                                                borderRadius: '0.5rem'}} 
                                            />

                                        <Typography 
                                            paragraph
                                            textAlign={'justify'}
                                            sx={{
                                                width: '95%',
                                                fontSize: {xs: 18, md: 20},
                                                lineHeight: {xs: 1.5, md: 2},
                                                fontFamily: 'Neuton',
                                                fontWeight: 300, 
                                                color: grey[900]}}>

                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>

                        </Box>

                        {/* CORPORATE ITEMS - MD to XL*/}
                        <Box sx={{
                            display: {xs: 'none', md: 'flex'},
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'orange',
                            mt: 1
                            }}>

                            <Box sx={{
                                display: 'flex',
                                // flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '95%',
                                // background: 'blue',
                                }}>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    width: '125%',
                                    // background: 'yellow',
                                    mr: '5%',
                                    }}>
                                    <Typography 
                                        paragraph
                                        textAlign={'justify'}
                                        sx={{
                                            width: '100%',
                                            fontSize: {xs: 18, md: 20},
                                            lineHeight: {xs: 1.5, md: 2},
                                            fontFamily: 'Neuton',
                                            fontWeight: 300, 
                                            color: grey[900]}}>
                                            For our corporate clients, we specialize in creating tailored catering solutions for breakfasts, 
                                            lunches, team-building activities, and executive brunches. We understand the importance of 
                                            these events in fostering team spirit and productivity, and our gourmet offerings are crafted 
                                            to impress and energize.
                                    </Typography>
                                    <Typography 
                                        paragraph
                                        textAlign={'justify'}
                                        sx={{
                                            width: '100%',
                                            fontSize: {xs: 18, md: 20},
                                            lineHeight: {xs: 1.5, md: 2},
                                            fontFamily: 'Neuton',
                                            fontWeight: 300, 
                                            color: grey[900]}}>
                                            From nutritious and delicious breakfast options to sophisticated brunch 
                                            spreads and hearty lunches, our menus are designed to meet the diverse needs of modern corporate 
                                            settings. We aim to elevate every corporate gathering with our fresh, flavorful, and beautifully 
                                            presented dishes.
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    width: '75%',
                                    // background: 'yellow',
                                    }}>
                                    <img 
                                        src={Biz} 
                                        // src={Biz2} 
                                        alt="Series of gift boxes" 
                                        style={{
                                            maxHeight: '100%', 
                                            maxWidth: '100%', 
                                            borderRadius: '0.5rem'}} 
                                        />
                                </Box>
                            </Box>

                        </Box>

                        {/* PERSONAL HEADER */}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'teal',
                            mt: 1, 
                            }}>
                            <Typography 
                                textAlign={'left'}
                                gutterBottom
                                sx={{
                                    lineHeight: 2,
                                    width: '95%',
                                    fontSize: {xs: 24, md: 28},
                                    fontFamily: 'Neuton',
                                    fontWeight: 400, 
                                    color: grey[900]}}>
                                Personal & Special Events
                            </Typography>
                        </Box>

                        {/* PERSONAL ITEMS - XS to MD*/}
                        <Box sx={{
                            display: {xs: 'flex', md: 'none'},
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'pink',
                            mt: 1,
                            mb: '1rem'
                            }}>

                            <Grid container>

                                <Grid item xs={12} lg={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        width: '100%',
                                        mt: 1
                                            }}>
                                        <Typography 
                                            paragraph
                                            textAlign={'justify'}
                                            sx={{
                                                width: '95%',
                                                fontSize: {xs: 18, md: 20},
                                                lineHeight: {xs: 1.5, md: 2},
                                                fontFamily: 'Neuton',
                                                fontWeight: 300, 
                                                color: grey[900]}}>
                                                    Our special events catering features elegantly prepared grazing boxes, perfect for gifting 
                                                    on occasions like Mother's Day or simply as a gesture of love and care. These stylish boxes 
                                                    are a feast for the eyes and the palate, combining a selection of fruits, sweet and savory 
                                                    snacks, and cured meats. Each box is thoughtfully curated to provide a balanced and delightful 
                                                    tasting experience, making them an ideal gift that stands out. Customers can conveniently 
                                                    place their orders through our website or Instagram. 
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} lg={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        width: '100%',
                                        mt: 1
                                        }}>
                                        <img 
                                            src={Pers} 
                                            alt="Series of gift boxes" 
                                            style={{maxHeight: '100%', maxWidth: '95%', borderRadius: '0.5rem'}} />
                                    </Box>
                                    <Typography 
                                        paragraph
                                        textAlign={'justify'}
                                        sx={{
                                            width: '95%',
                                            fontSize: {xs: 18, md: 20},
                                            lineHeight: {xs: 1.5, md: 2},
                                            fontFamily: 'Neuton',
                                            fontWeight: 300, 
                                            color: grey[900]}}>

                                    </Typography>
                                </Grid>

                            </Grid>


                        </Box>

                        {/* PERSONAL ITEMS - MD to XL*/}
                        <Box sx={{
                            display: {xs: 'none', md: 'flex'},
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            mb: '1rem',
                            mt: 1
                            }}>

                            <Box sx={{
                                display: 'flex',
                                // flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '95%',
                                // background: 'blue',
                                }}>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    width: '75%',
                                    // background: 'yellow',
                                    mb: '1rem',
                                    }}>
                                    <img 
                                        src={Pers2} 
                                        alt="Series of gift boxes" 
                                        style={{
                                            maxHeight: '100%', 
                                            maxWidth: '100%', 
                                            borderRadius: '0.5rem'}} 
                                        />
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    width: '125%',
                                    // background: 'yellow',
                                    mb: '1rem',
                                    ml: '5%',
                                    }}>

                                    <Typography 
                                        paragraph
                                        textAlign={'justify'}
                                        sx={{
                                            width: '100%',
                                            fontSize: {xs: 18, md: 20},
                                            lineHeight: {xs: 1.5, md: 2},
                                            fontFamily: 'Neuton',
                                            fontWeight: 300, 
                                            // ml: '10%',
                                            color: grey[900]}}>
                                            Our special events catering features elegantly prepared grazing boxes, perfect for gifting 
                                            on occasions like Mother's Day or simply as a gesture of love and care. These stylish boxes 
                                            are a feast for the eyes and the palate, combining a selection of fruits, sweet and savory 
                                            snacks, and cured meats. Each box is thoughtfully curated to provide a balanced and delightful 
                                            tasting experience, making them an ideal gift that stands out. Customers can conveniently 
                                            place their orders through our website or Instagram. 
                                    </Typography>

                                </Box>
                            </Box>
                        </Box>

                        {/* DELIVERY HEADER*/}
                        {/* <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            // background: 'ivory',
                            mt: {xs: 1, md: 3}
                            }}>
                            <Typography 
                                textAlign={'left'}
                                gutterBottom
                                sx={{
                                    lineHeight: 2,
                                    width: '95%',
                                    fontSize: {xs: 24, md: 28},
                                    fontFamily: 'Blaster',
                                    fontWeight: 400, 
                                    color: grey[900]}}>
                                    Delivery Fees
                            </Typography>
                        </Box> */}

                        {/* DELIVERY AND OTHER ITEMS */}
                        {/* <Box sx={{
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '100%',
                            }}>
                            <Typography 
                                paragraph
                                textAlign={'justify'}
                                sx={{
                                    width: '95%',
                                    fontSize: {xs: 16, md: 20},
                                    lineHeight: {xs: 1.5, md: 2},
                                    fontFamily: 'Quicksand',
                                    fontWeight: 400, 
                                    color: grey[900]}}>
                                        With free delivery available for orders 
                                        of ten or more within an 8-mile radius and for a fee beyond that. We are dedicated to making 
                                        every occasion special with our high-quality, fresh, and beautifully presented offerings.
                            </Typography>
                        </Box> */}

                    </Stack>

                </Container>
            </Layout>
        </div>
    )
}

export default Homepage