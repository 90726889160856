import React, { useContext, useEffect, useState }  from "react";
import { CartContext } from "../context/cart-context";
// import { ProductsContext } from "../context/products-context";
// import { isInCart } from '../helpers';
import { 
    Card, 
    CardMedia, 
    Typography, 
    Grid, 
    CardActionArea, 
    CardHeader, 
    Button, 
    Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { Link } from 'react-router-dom';
import ColorButton from "./coloredButton";

import { styled } from '@mui/material/styles';

const StyledCardActionArea = styled(CardActionArea)(({theme}) => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`);

function ProductGallery({ payload }) {

    // const { products } = useContext(ProductsContext)
    const { addProduct } = useContext(CartContext)

    // const theme = useTheme()
    const navigate = useNavigate()

    // const [product, setProduct] = useState(null)

    // const SB = styled(Button)(({theme}) => ({
    //     color: 'blue'
    // }))

    // const StyledCard = styled(Card)(({ theme }) => ({
    //     '&:hover': {
    //         root: {
    //             Button: {
    //                 color: 'purple'
    //             }
    //         }
    //     },
    //     // root: {
    //     //     "& .hidden-button": {
    //     //         display: "none"
    //     //     },
    //     //     "&:hover .hidden-button": {
    //     //     display: "flex"
    //     //     },
    //     // },
    // }))

    // const BGO = '#F3EDE0'


    

    // const { id, name, title, imageUrl, variations } = payload
    const { id, name, title, imageUrl, variations, minOrder } = payload

    useState( () => {

        return( () => {})

    }, [])

    return(
        <Grid item xs={10} sm={5} md={4} key={id} >
            <Card 
                variant='outlined' 
                sx={{
                    borderWidth: 0,
                    background: alpha('#F8F6F1', 0.6),
                    '&:hover': {
                        zIndex: 1,
                        borderWidth: 1,
                        background: alpha('#F8F6F1', 0.75),
                    },
                    }}>
                <StyledCardActionArea 
                sx={{
                    fontFamily: 'inherit',
                    // ":focus": {background: alpha('#F8F6F1', 0.75)},
                    // '&:hover': {
                    //     background: alpha('#F8F6F1', 0.75),
                    // },
                }} 
                component={Link} 
                to={`/product/${name}`}>
                <CardMedia
                    component={'img'}
                    image={imageUrl[0]}
                    title={title}
                    // onClick={() => {navigate(`/product/${name}`)}}
                    />
                <CardHeader 
                    disableTypography 
                    title={
                        <Typography 
                        textAlign={'center'} 
                        sx={{
                            fontSize: {xs: 24, md: 18},
                            mb: '1rem',
                            // lineHeight: 2,  
                            fontFamily:'Neuton',
                            color: grey[700], 
                            fontWeight: 300}}>
                            {title}, {variations[0].type}
                        </Typography>
                        }
                    subheader={
                        <Typography 
                        textAlign={'center'} 
                        sx={{fontSize: {xs: 20, md: 18}, 
                            // lineHeight: 1.5,
                            fontFamily:'Neuton',
                            color: grey[800], 
                            fontWeight: 300}}>
                            {/* {variations[0].type} */}
                            $ {variations[0].price}
                        </Typography>
                    }/>
                </StyledCardActionArea>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    }}>

                    <ColorButton
                        variant='contained' 
                        disableElevation
                        onClick={() => {
                            addProduct(
                                {
                                    product: payload, 
                                    Price: variations[0].price, 
                                    Var: variations[0], 
                                    subid: id,
                                    minOrder: minOrder,
                                }
                            )
                        }} 
                        sx={{
                            // height: '2rem',
                            // background: theme.palette.primary.main,
                            // background: '#8a8a5f',
                            // fontFamily: 'inherit',
                            width: {xs: '80%', md: '50%'},
                            // background: alpha(BGO, 0.4),
                            // color: "white",
                            mt: '1rem',
                            mb: '1rem'
                        }}>
                        Add to Cart
                    </ColorButton>
                    
                </Box>
            </Card>
        </Grid>

    )
}

export default ProductGallery