import React from "react";
import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
// import { ProductsContext } from "../context/products-context";
import { 
    Typography, 
    Box, 
    Container,
    // Button, 
    // Grid 
} from "@mui/material";
import { Helmet } from 'react-helmet';
import { grey } from "@mui/material/colors";


export default function About() {

    return(
        <div>
            <Helmet>
                <title>About Pantry</title>
                {/* NOTE: HAS TO BE CHANGED */}
                <meta name="description" content={'affordable private chef, and catering for events'} />
                <meta name="keywords" content="best in OC, best in LA, affordable private chef, catering, private event, baby shower, engagement, corporate dining" />

                <meta property="og:title" content={'Welcome to The Pantry'} />
                <meta property="og:description" content={'affordable private chef, and catering for events'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://thepantry.catering'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Welcome to The Pantry'} />
                <meta name="twitter:description" content={'affordable private chef, and catering for events'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg'>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', 
                        width: '100%',
                        pt: '3rem',
                        }}>

                        <Typography sx={{fontSize: 28, color: grey[400]}}>
                            About. 
                        </Typography>

                    </Box>

                </Container>
            </Layout>
        </div>
    )
}