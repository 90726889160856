
const stripe = require('stripe')(process.env.REACT_APP_SECRET_KEY);

async function CheckOut(props) {

    let li = []

    props.map( (item) => {

        console.log(item)

        return li.push({
            price_data: {
                currency: "usd",
                product_data: {
                    // name: item.product.title.concat('-', item.Var.type),
                    name: item.product.title.concat('-', item.product.subTitle),
                    description: item.product.description[0],
                    images: [
                        item.product.imageUrl[1],
                    ],
                    metadata: [],
                },
                unit_amount: Math.round( 100 * item.Price ),
            },
            quantity: item.quantity,
        })
    })

    const payload = {
        automatic_tax: {
            enabled: true,
            // "liability": null,
            // "status": null
          },
        line_items: li,
        mode: 'payment',
        success_url: 'http://thepantry.catering/success?session_id={CHECKOUT_SESSION_ID}',
        shipping_address_collection: {
            allowed_countries: ['US'],
          },
        shipping_options: [
            {
              shipping_rate_data: {
                type: 'fixed_amount',
                fixed_amount: {
                  amount: 1000,
                  currency: 'usd',
                },
                display_name: 'Ground shipping',
                delivery_estimate: {
                  minimum: {
                    unit: 'business_day',
                    value: 5,
                  },
                  maximum: {
                    unit: 'business_day',
                    value: 7,
                  },
                },
              },
            },
            {
              shipping_rate_data: {
                type: 'fixed_amount',
                fixed_amount: {
                  amount: 1500,
                  currency: 'usd',
                },
                display_name: 'Next day air',
                delivery_estimate: {
                  minimum: {
                    unit: 'business_day',
                    value: 1,
                  },
                  maximum: {
                    unit: 'business_day',
                    value: 3,
                  },
                },
              },
            },
          ],
    }

    const session = await stripe.checkout.sessions.create(
        payload
    )

    return {id: session.id}


}

export default CheckOut