import React, { useContext } from "react";
import { CartContext } from "../context/cart-context";
import Layout from "./layout";
import { Typography, Box } from "@mui/material";
import StripeCheckout from './stripe-checkout'

const Checkout = () => {

    const {itemCount, total} = useContext(CartContext)

    return(
        <Layout>
            <Box sx={{display: 'block', alignContent: 'center', justifyContent: 'center'}}>
                <Box sx={{mt: '2%', mb:'2%'}}>
                <Typography variant='h4' gutterBottom  textAlign={'center'}>
                    Checkout Summary
                </Typography>
                </Box>

                <Box sx={{mt: '2%', mb:'2%'}}>
                <Typography variant='body1' gutterBottom textAlign={'center'}>
                    Total Items: {itemCount}
                </Typography>
                </Box>

                <Box sx={{mt: '2%', mb:'2%'}}>
                <Typography variant='body1' gutterBottom textAlign={'center'}>
                    Total Amount to pay: $ {total}
                </Typography>
                </Box>

                <StripeCheckout />
            </Box>
        </Layout>

    )
}

export default Checkout