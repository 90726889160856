import React, { useContext, useState, useEffect } from "react";
import { ProductsContext } from "../context/products-context";
import Layout from "./layout";
import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
import { CardMedia, Typography, Box, Button, Container, Grid, Accordion, Card, Divider, Tab, Tabs, TextField, AppBar, Toolbar, Stack } from "@mui/material";
import { CartContext } from "../context/cart-context";
import { isInCart } from "../helpers";
import { grey, yellow } from "@mui/material/colors";
import { styled } from '@mui/material/styles';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';
import IconBreadcrumbs from "./iconBreadCrumbs";
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ColorButton from "./coloredButton";
import TitleBlock from "./demoTitleBlock";
import DeliveryBlock from "./demoDeliveryBlock";
import NewsLetter from "./demoNewsLetter";
import NewsLetter2 from "./demoNewsLetter2";
import PantryProgress from "./pantryCircularProgress";
import EastIcon from '@mui/icons-material/East';
// import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

 
// function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//         let location = useLocation();
//         let navigate = useNavigate();
//         let params = useParams();
//         return (
//         <Component
//             {...props}
//             router={{ location, navigate, params }}
//         />
//         );
//     }

//     return ComponentWithRouterProp;
// }

// const ColorButton = styled(Button)(({ theme }) => ({
//     color: theme.palette.getContrastText(grey[600]),
//     lineHeight: 2,
//     backgroundColor: yellow[600],
//     // backgroundColor: '#ffde59',
//     fontSize: 14,
//     fontWeight: 400,
//     fontFamily: 'inherit',
//     '&:hover': {
//       backgroundColor: grey[800],
//       color: theme.palette.getContrastText(grey[800]),
//       lineHeight: 2,
//       fontWeight: 500,
//     },
//   }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{pt: 3, pb: 3}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function AA({d}) {

    return(
        <>
            {d.map(i=> 
                <Typography 
                    textAlign={'justify'} 
                    sx={{
                        marginBottom: '14px',
                        fontFamily: 'Neuton',
                        fontWeight: 300,
                        fontSize: {xs: 16, md: 18}
                        }}>
                        {i}
                </Typography>
            )}
        </>
    )
}

function PantryItem({match}) {

    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const {products} = useContext(ProductsContext)
    const { addProduct, cartItems } = useContext(CartContext)
    // const navigate = useNavigate()
    const [product, setProduct] = useState(null)
    const [desk, setDesks] = useState([])
    const [aromas, setAromas] = useState([])

    const [itemsInCart, setItems] = useState(null)
    // const [subId, setSubId] = useState('')
    // const [name, setName] = useState('')

    // const handleChangeLoc = (event) => {
    //     setLoc(event.target.value);
    // }

    // const handleChange = (event) => {
    //     setAge(Math.round(event.target.value));
    const [value, setValue] = useState(1);
    // }
    const theme = useTheme()

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

    useEffect(() =>{

        const product = products.find(item => item.name === id)

        console.log(product)

        if (product) {

            // console.log(product.description)
            // console.log(isInCart(product, cartItems))
            setItems(isInCart(product, cartItems))
            setProduct(product)
            setLoading(false)
            setDesks(product.description)
            setAromas(product.allergens)
            // console.log(desk)
        } else {
            // return navigate('/shop')
        }

        return( 
            ()=>{}
            )

    }, [id, products, desk, cartItems])

    return(
        <div>
            <Helmet>
                {/* <title>{name} Details</title> */}
                <meta name="description" content={'The pantry catering item details'} />
                <meta name="keywords" content="pantry, pantry bakery, pantry catering, event catering, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch, executive breakfast, corporate event, fresh, lunch box" />

                {/* <meta property="og:title" content={`${name} details`} /> */}
                <meta property="og:description" content={'The pantry catering item details'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={`https://thepantry.catering/product/${id}`} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={`${id} details`}  />
                <meta name="twitter:description" content={'The pantry catering item details'} />
            </Helmet>

            <Layout>
                <Container maxWidth='md'>
                    {
                        loading ? 

                        <PantryProgress />

                        :

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            marginTop: '3rem',
                            height: '100%',
                            width: '100%',
                            pt: '3rem',
                            pb: '3rem',
                            }}>

                            {/* BreadCrumbs done. */}
                            {/* <Box sx={{display: 'flex', width: '100%'}}>
                                <IconBreadcrumbs previous={'Home'} current={'Shop'}/>
                            </Box> */}
                            
                            {/* Carousel is done. */}
                            <Box sx={{
                                display: 'flex', 
                                alignContent: 'center', 
                                width: {xs: '80%', md: '60%'}, 
                                mt: '1rem',
                                // height: '25vh'
                                // mt: {xs: '2%', md: '1%'},
                                // background: 'red',
                                    // paddingBottom: '30px',
                                    // position: 'relative'
                                }}>
                                {/* <Carousel
                                    additionalTransfrom={0}
                                    arrows
                                    autoPlaySpeed={3000}
                                    centerMode={false}
                                    className=""
                                    containerClass="container"
                                    dotListClass=""
                                    draggable
                                    focusOnSelect={false}
                                    infinite
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={80}
                                    pauseOnHover
                                    renderArrowsWhenDisabled={false}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside
                                    responsive={{
                                    desktop: {
                                        breakpoint: {
                                        max: 3000,
                                        min: 1024
                                        },
                                        items: 1
                                    },
                                    mobile: {
                                        breakpoint: {
                                        max: 464,
                                        min: 0
                                        },
                                        items: 1
                                    },
                                    tablet: {
                                        breakpoint: {
                                        max: 1024,
                                        min: 464
                                        },
                                        items: 1
                                    }
                                    }}
                                    rewind={false}
                                    rewindWithAnimation={false}
                                    rtl={false}
                                    shouldResetAutoplay
                                    showDots
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                    >
                                    <div>Item 1</div>
                                    <div>Item 2</div>
                                    <div>Item 3</div>
                                    <div>Item 4</div>
                                </Carousel> */}



                                <Carousel 
                                    showArrows={true}
                                    showStatus={false}
                                    autoPlay={false}
                                    // infiniteLoop={true}
                                    showIndicators={true} 
                                    showThumbs={false}
                                    >
                                    {
                                        product.imageUrl.map( (img, index) => 
                                            <div key={index}>
                                                <img 
                                                    src={img} 
                                                    width={'50%'} 
                                                    style={{borderRadius: '0.5rem'}}
                                                    alt="View from top"/>
                                            </div>
                                        )
                                    }

                                </Carousel>
                            </Box>

                            {/* Product Title is done. */}
                            <Box 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    mt: '1rem',
                                    }}>
                                <TitleBlock Name={product.title} Size={product.subTitle} />
                            </Box>
                            
                            {/* Price is done. */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                // mt: '1rem',
                                }}>
                                <Typography 
                                    gutterBottom
                                    sx={{
                                        fontSize: {xs: 20, md: 24}, 
                                        lineHeight: 1.5,
                                        fontFamily: 'Neuton', 
                                        fontWeight: 500}}>
                                        ${product.variations[0].price}
                                </Typography>
                            </Box>

                            {/* Description tabs is done. */}
                            <Box sx={{ 
                                width: '100%', 
                                // background: 'teal' 
                                }}>
                                {/* <Tabs 
                                    variant='fullWidth'
                                    value={value} 
                                    onChange={handleChange}
                                    textColor="Secondary"
                                    indicatorColor="primary" 
                                    centered
                                    sx={{fontFamily: 'Neuton'}}
                                    >
                                    <Tab 
                                        value={1} 
                                        label="Description" 
                                        sx={{
                                            fontFamily: 'Neuton',
                                            fontWeight: 500, 
                                            fontSize: {xs: 16, md: 20},
                                            textTransform: 'none',
                                            }}/> */}
                                    {/* <Tab 
                                        value={2} 
                                        label="Nutritional" 
                                        sx={{
                                            fontFamily: 'inherit',
                                            fontWeight: 500, 
                                            fontSize: {xs: 16, md: 20},
                                            textTransform: 'none',
                                        }}/> */}
                                {/* </Tabs> */}
                                {/* <TabPanel value={value} index={1} dir={theme.direction}> */}

                                    <AA d={desk}/>

                                {/* </TabPanel> */}

                                {/* <TabPanel value={value} index={2} dir={theme.direction}>
                                    Item Two
                                </TabPanel> */}
                            </Box>

                            {/* Aroma and Notes */}
                            <Box sx={{ 
                                width: '100%', 
                                // background: 'teal' 
                                }}>
                                <Typography 
                                    textAlign={'left'} 
                                    gutterBottom
                                    sx={{
                                        fontSize: {xs: 28, md: 32}, 
                                        lineHeight: 1.5,
                                        fontFamily: 'Neuton', 
                                        fontWeight: 300,
                                        color: grey[800],
                                        }}>
                                    Aromas
                                </Typography>
                            </Box>

                            <Box sx={{ 
                                width: '100%',
                                }}>
                                <Stack direction="row" spacing={2} useFlexGap>
                                    {aromas.map(i=> 
                                        <>
                                        <img src={i.image} width={'50px'} alt></img>
                                        <Typography 
                                            textAlign={'justify'} 
                                            sx={{
                                                marginBottom: '14px',
                                                fontFamily: 'Neuton',
                                                fontWeight: 300,
                                                fontSize: {xs: 16, md: 18}
                                                }}>
                                                {i.name}
                                        </Typography>
                                        </>
                                    )}
                                    {/* <Typography>A</Typography>
                                    <Typography>B</Typography>
                                    <Typography>C</Typography> */}
                                </Stack>
                            </Box>

                            {/* Add to Cart button is done. */}
                            <Box 
                                sx={{
                                    display: {xs: 'none', md: 'flex'},
                                    // flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    // mt: '1rem',
                            }}>
                                <ColorButton 
                                    // disabled={ variation === '' ? true : false}
                                    variant='contained' 
                                    disableElevation
                                    sx={{
                                        width: '40%',
                                        mt: '1rem',
                                        mb: '2rem',
                                    }}
                                    onClick={() => {
                                        addProduct(
                                            {
                                                product: product, 
                                                Price: product.variations[0].price, 
                                                Var: product.variations[0], 
                                                subid: product.variations[0].subId,
                                                minOrder: product.minOrder,
                                            }
                                        )
                                    }}
                                    >
                                        PREORDER
                                </ColorButton>
                            </Box>

                            {/* Delivery Box is completed. */}
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                    width: '100%', 
                                    mt: '1rem',
                                    background: '#F8F6F1',
                                    }}>
                                <Box
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center', 
                                        width: '95%',
                                        m: '1rem',
                                        }}>
                                    <NewsLetter2/>
                                </Box>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                width: '100%', 
                                mt: '1rem',
                                }}>
                                <Typography 
                                    textAlign={'left'}
                                    sx={{
                                        lineHeight: 2,
                                        fontFamily: 'Neuton',
                                        // marginBottom: '16px',
                                        fontSize: {xs: 20, md: 16}
                                        }}>
                                    Related Products
                                </Typography>
                            </Box>

                            {/* News letter is completed. */}
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                    width: '100%', 
                                    mt: '1rem',
                                    background: '#F8F6F1',
                                    border: 'blue'
                                    }}>
                                <Box
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center', 
                                        width: '95%',
                                        m: '1rem',
                                        }}>
                                    <NewsLetter/>
                                </Box>
                            </Box>

                            {/* Floating button is done.*/}
                            <AppBar 
                                position='fixed' 
                                elevation={0}
                                sx={{
                                    display: {xs: 'flex', md: 'none'},
                                    width: '100%', 
                                    height: '5rem', 
                                    justifyContent: 'center', 
                                    top: 'auto', 
                                    bottom: 0, 
                                    background: '#F3EDE0'}}>
                                    <Toolbar sx={{                
                                        borderTop: 1,
                                        borderColor: 'primary.main'}}>
                                    <ColorButton 
                                        // disabled={ variation === '' ? true : false}
                                        variant='contained' 
                                        disableElevation
                                        sx={{
                                            width: '98%',
                                            mt: {xs: '2%', md: '2%'},
                                            mb: {xs: '2%', md: '2%'},
                                        }}
                                        onClick={() => {
                                            // addProduct(
                                            //     {
                                            //         product: product, 
                                            //         Price: product.variations[0].price, 
                                            //         Var: product.variations[0], 
                                            //         subid: product.variations[0].subId,
                                            //         minOrder: product.minOrder,
                                            //     }
                                            // )
                                        }}
                                        >
                                            ADD TO CART
                                    </ColorButton>
                                </Toolbar>
                            </AppBar>

                        </Box>
                    }
                </Container>
            </Layout>

        </div>
    )

}


export default PantryItem