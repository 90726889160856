// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
// import { setDoc, doc, getDoc } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 
// and later, measurementId is optional
const firebaseConfig = {
    // apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTHDOM,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCK,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MSGID,
    // appId: process.env.REACT_APP_FIREBASE_APPID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREID
    apiKey: "AIzaSyBvQbAp6r6GOosxbwpKUovS2IAfp9iO5HU",
    authDomain: "webstore-35f8c.firebaseapp.com",
    projectId: "webstore-35f8c",
    storageBucket: "webstore-35f8c.appspot.com",
    messagingSenderId: "211769940718",
    appId: "1:211769940718:web:8700f68025794d6ebbb1ff",
    measurementId: "G-XVHG1W8K92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize SDKs
const analytics = getAnalytics(app);
const db = getFirestore(app)
const auth = getAuth()
const functions = getFunctions(app)

export {
    auth,
    db,
    functions,
    analytics,
}