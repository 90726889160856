import React, { useState } from "react";
import Layout from "../components/layout";
import { Box, 
    // Card, 
    // CardMedia, 
    Container, 
    // List,
    // ListItem,
    Divider, 
    Button,
    // ToggleButton, 
    // ToggleButtonGroup, 
    Typography, 
    Stack} from "@mui/material";
import { grey } from "@mui/material/colors";
import CircleIcon from '@mui/icons-material/Circle';
import TT from '../assets/insidebox.jpg';
import { Link } from 'react-router-dom';

export default function Inside() {

    return (
        <Layout>
            <Container maxWidth='lg'>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '4%',
                    mb: '2%',
                    }}>
                    <Typography textAlign={'center'} sx={{
                        // fontFamily: ''
                        fontSize:  {xs: 24, md: 30}, 
                        fontWeight: 500, 
                        lineHeight: 2, 
                        }}>
                        What's in My Box?
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '4%',
                    mb: '4%',
                    }}>
                    <Typography textAlign={'center'} sx={{
                        // fontFamily: ''
                        fontSize:  {xs: 18, md: 24}, 
                        fontWeight: 400, 
                        lineHeight: 2, 
                        }}>
                        Monday, June 3, 2024
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '2%',
                    mb: '2%',
                    }}>
                    <Divider flexItem variant='middle' style={{width:'80%'}}/>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '3%',
                    mb: '3%',
                    }}>
                    <Typography 
                        textAlign={'center'} 
                        sx={{
                            fontSize:  {xs: 24, md: 30}, 
                            fontWeight: 500, 
                            lineHeight: 2, 
                            }}>
                        Pantry Sandwich Box
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '2%',
                    mb: '2%',
                    // background: 'blue',
                    }}>
                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: {xs: '95%', md: '80%'},}}>
                            <img 
                                src={TT} 
                                alt="bunch of herbs" 
                                style={{
                                    maxHeight: '20%', 
                                    maxWidth: '100%', 
                                    borderRadius: '0.5rem'
                                    }} />
                        </Box>

                </Box>

                <Stack rowGap={2} sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '4%',
                    }}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mb: '1%',
                        }}>
                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                // ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 500, 
                                color: grey[900]}}>
                                Today's "Pantry Sandwich Box", includes:
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mt: 1,
                        }}>

                        <CircleIcon sx={{ fontSize: {xs: 8, md: 10} }}/>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 400, 
                                color: grey[900]}}>
                                Organic Turkey Breast Croissant Sandwich
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mt: 1,
                        }}>
                            
                        <CircleIcon sx={{ fontSize: {xs: 8, md: 10} }}/>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 400, 
                                color: grey[900]}}>
                                Overnight Oatmeal with Chia Seeds (V)
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        }}>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 3,
                                fontSize: {xs: 16, md: 20},
                                fontStyle: 'italic', 
                                fontWeight: 300, 
                                color: grey[900]}}>
                                Prepared with Almont Milk, Berries, Mint
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mt: 1,
                        }}>
                            
                        <CircleIcon sx={{ fontSize: {xs: 8, md: 10} }}/>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 400, 
                                color: grey[900]}}>
                                Seasonal Fruits (V)
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        }}>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 3,
                                fontSize: {xs: 16, md: 20},
                                fontStyle: 'italic', 
                                fontWeight: 300, 
                                color: grey[900]}}>
                                Strawberry, Blackberry, Blueberry, Lychee and Appricot
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mt: 1,
                        }}>
                            
                        <CircleIcon sx={{ fontSize: {xs: 8, md: 10} }}/>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 400, 
                                color: grey[900]}}>
                                Financier
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        }}>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 3,
                                fontSize: {xs: 16, md: 20},
                                fontStyle: 'italic', 
                                fontWeight: 300, 
                                color: grey[900]}}>
                                Mini Almont Cake
                        </Typography>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mt: 1,
                        }}>
                            
                        <CircleIcon sx={{ fontSize: {xs: 8, md: 10} }}/>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 400, 
                                color: grey[900]}}>
                                Cheese Bits
                        </Typography>

                    </Box>

                    {/* <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        width: {xs: '90%', sm: '80%', md: '70%'},
                        mt: 1,
                        }}>
                            
                        <CircleIcon sx={{ fontSize: {xs: 8, md: 10} }}/>

                        <Typography 
                            textAlign={'justify'}
                            sx={{
                                // width: '95%',
                                ml: 1,
                                fontSize: {xs: 16, md: 20},
                                // fontStyle: 'italic', 
                                fontWeight: 400, 
                                color: grey[900]}}>
                                Mixed Nuts (V)
                        </Typography>

                    </Box> */}
                    

                </Stack>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '4%',
                    mb: '2%',
                    }}>
                    <Typography textAlign={'center'} sx={{
                        // fontFamily: ''
                        fontSize:  {xs: 18, md: 24}, 
                        fontStyle: 'italic',
                        fontWeight: 500, 
                        lineHeight: 2, 
                        }}>
                        Please enjoy and follow me on IG:
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '2%',
                    mb: '4%',
                    }}>

                    <Button
                        component={Link} 
                        variant='text'
                        to="http://www.instagram.com/thepantry.catering"
                        color="secondary"
                        >
                        <Typography textAlign={'center'} sx={{
                            // fontFamily: ''
                            fontSize:  {xs: 18, md: 24}, 
                            // fontStyle: 'italic',
                            fontWeight: 400, 
                            lineHeight: 2, 
                            }}>
                            @thepantry.catering
                        </Typography>
                    </Button>

                </Box>


            </Container>
        </Layout>

    )

}